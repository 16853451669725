@import "./color-style.scss";
@import "./support.scss";

.project-title {
  font-size: $font-size-xxl;
  line-height: normal;
  margin-bottom: 0;
}

.ant-table {
  background: transparent;
}

.ant-table-cell {
  color: $baseWhite;
}
span.ant-select-selection-item {
  text-transform: capitalize;
}
.ant-select-selection-item {
  color: $baseWhite;
}

.ant-select-item-option-content {
  color: $baseWhite;
}

.ant-input {
  color: $baseWhite;
}

.ant-btn-link {
  //instead of deepRed
  color: $baseWhite;
  font-size: medium;
}

.ant-tooltip-inner {
  background-color: #25050a;
}

.export-link {
  color: $baseWhite;

  &:hover {
    color: $baseWhite;
  }

  .anticon-close {
    position: relative;
    bottom: 2px;
  }
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: none !important;
}

.pipeline-table {
  padding: 0 30px;
  padding-bottom: 30px;

  .ant-table-tbody {
    tr {
      td {
        padding: 10px !important;
        font-size: 14px;
      }

      td:first-child {
        padding-left: 20px !important;
      }
    }
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent;
        border-bottom: none;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 500 !important;
        color: $baseRed;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }

  .edit-hover {
    transition: 0.2s linear;
    color: $baseRed;
  }
}

.toolbox-table {
  padding-bottom: 30px;
  padding-top: 30px;

  .ant-table-tbody {
    tr {
      td {
        padding: 10px !important;
        font-size: 14px;
      }

      td:first-child {
        padding-left: 20px !important;
      }

      //   td:last-child {
      //     flex: 1;
      //     display:inline-block;
      // }
    }
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent;
        border-bottom: none;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 500 !important;
        color: $baseRed;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }

  .edit-hover {
    transition: 0.2s linear;
    color: $baseRed;
  }
}

.table-head-yellow {
  tr {
    th {
      color: $baseRed;
      font-weight: 400;
    }
  }
}

.ant-table-thead {
  font-size: $font-size-base;

  tr {
    th {
      background: transparent;
      border-bottom: none;
      vertical-align: top;
      white-space: nowrap;
      font-weight: 500 !important;

      &.ant-table-cell-scrollbar {
        box-shadow: none;
      }
    }
  }
}

.quality-report-table {
  .ant-table-thead {
    tr {
      th {
        white-space: normal;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        white-space: normal !important;
      }
    }
  }
}

.ant-table-ping-right:not(.ant-table-has-fix-right)
  .ant-table-container::after {
  box-shadow: none;
}

.ant-modal {
  .ant-modal-footer {
    border-top: none;
    padding: 0;

    .ant-btn {
      padding: 8px 20px;
      height: 40px;
      font-weight: 700;
      background: transparent;
      color: $baseWhite;
      border-color: $baseWhite;
      border-radius: 30px;
    }

    .ant-btn-primary {
      background: $baseRed;
      color: $baseWhite;
      border-color: $baseWhite;
    }
  }

  .ant-table {
    background: transparent !important;
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent !important;
        border-bottom: none !important;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }

      .main-title {
        background: $input-bg 0% 0% no-repeat padding-box !important;
        border-radius: 46px !important;
        color: $baseRed;
      }
    }
  }

  .ant-table-tbody {
    tr.ant-table-row {
      border-radius: 30px;

      &:nth-child(even) {
        background: $table-bg-white !important;
      }

      &:hover > td {
        background: transparent !important;
      }

      td {
        border-bottom: none !important;
        border: none;
      }
    }
  }
}
.ant-table-container table > thead > tr th {
  color: red;
}
.ant-table-tbody {
  tr.ant-table-row {
    border-radius: 30px;

    &:nth-child(even) {
      background: $table-bg-white;
    }

    &:hover > td {
      background: transparent !important;
    }

    td {
      border-bottom: none;
      vertical-align: top;
    }
  }
}

tr:nth-child(even) {
  td:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  td:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

.ant-drawer-logo {
  background-repeat: round;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-top: 0px;
}
.ant-tree-switcher {
  width: 15px;
}

span.ant-tree-title {
  color: $baseWhite;
}
span.ant-tree-title:active {
  color: $baseRed;
}
.ant-layout {
  background: transparent;
}

.ant-layout-sider {
  background: transparent;
}

.ant-layout-sider-trigger {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000a3 100%)
    0% 0% no-repeat padding-box;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-inline::after {
  border-right: none;
}

.left-menu {
  background: transparent;
  border-right: none;

  .ant-menu-item {
    font-size: $font-size-base;

    &::after {
      content: none;
    }

    &:hover {
      color: $baseRed;
    }

    &:active {
      background: transparent !important;
    }

    a {
      &:hover {
        color: $baseRed;
      }
    }

    .ant-btn-link {
      padding-left: 0;
      color: $text-color-white;
      display: flex;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        color: $baseRed;
      }
    }
  }

  .ant-menu-item-selected {
    color: $baseRed;

    a,
    .ant-btn-link {
      color: $baseRed;

      &:active {
        background: transparent !important;
      }
    }
  }
}
.switchTheme {
  .icon-14 {
    width: 14px;
    height: 25px;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.logo-container {
  padding-left: 24px;
  padding-top: 24px;

  .aside-logo-small {
    display: none;
    cursor: pointer;
  }

  .logo-wrapper {
    .aside-logo-large {
      display: grid;

      img {
        width: 100%;
      }

      .logo-text {
        position: relative;
        top: 8px;
        font-size: 14px;
        font-weight: 800;
        color: $baseRed;
        text-shadow: 1px 1px 0px #ef314c7d;
      }
    }

    .aside-logo-small {
      img {
        width: 60px;
      }
    }
  }
}

.user-name {
  p {
    color: $text-color-white;
  }

  .ant-avatar {
    color: $baseRed;
  }
}

.first-last-letter {
  display: none;
}

.full-name {
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.user-logo {
  position: relative;
  right: 14px;
  top: 10px;
  // left: 0;
  cursor: pointer;
}

.ant-layout-sider-collapsed {
  .aside-logo-small {
    display: block;
    margin: auto;
  }

  .aside-logo-large {
    display: none !important;
  }

  .poweredby,
  .menu-content {
    opacity: 0;
  }

  .first-last-letter {
    display: block;
  }

  .full-name {
    display: none;
  }

  .user-logo {
    left: 2px;
  }

  .logo-container {
    padding-left: 0;
    text-align: center;
  }
}

.ant-layout-header {
  background: transparent;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 50px;
}

//silder
.flexslider,
.fourflexslider {
  border-radius: 14px;
  overflow-x: hidden;

  .rec-carousel {
    position: relative;
    overflow: hidden;
  }

  .rec-arrow {
    position: absolute;
    top: 0;
    border: none;
    height: 106px;
    box-shadow: none;
    border-radius: 0;
    color: #ffffff89;
    z-index: 1;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      box-shadow: none;
    }
  }

  .rec-arrow-left {
    left: 0;
    background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0%
      no-repeat padding-box;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: none;
    }
  }

  .rec-arrow-right {
    right: 0;
    background: linear-gradient(267deg, #251f01d6 0%, #00000000 100%) 0% 0%
      no-repeat padding-box;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      background: linear-gradient(267deg, #251f01d6 0%, #00000000 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: none;
    }
  }

  .rec-slider-container {
    margin-left: 46px;
    margin-right: 36px;
  }
}

.fourflexslider {
  .rec-item-wrapper {
    max-width: 212px;
  }
}
.flexslider-small {
  border-radius: 14px;
  overflow-x: hidden;

  .rec-item-wrapper {
    max-width: 212px;
  }

  .rec-carousel {
    position: relative;
    overflow: hidden;
    // margin-bottom: 16px;
  }

  .rec-arrow {
    position: absolute;
    top: 0;
    border: none;
    height: 76px;
    box-shadow: none;
    border-radius: 0;
    color: #ffffff89;
    z-index: 1;
  }

  .rec-arrow-left {
    left: 0;
    background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0%
      no-repeat padding-box;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: none;
    }
  }

  .rec-arrow-right {
    right: 0;
    background: linear-gradient(267deg, #251f01d6 0%, #00000000 100%) 0% 0%
      no-repeat padding-box;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      background: linear-gradient(267deg, #251f01d6 0%, #00000000 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: none;
    }
  }

  .rec-slider-container {
    margin-left: 46px;
    margin-right: 36px;
  }
}

.slides {
  height: 106px;
  overflow: hidden;
  width: 100%;

  .slides-items {
    background: $card-bg-white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    height: 100%;
    list-style: none;
    padding: 15px;
    margin-right: 16px;
    color: $baseWhite;
    font-size: $font-size-base;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 212px;

    &.active {
      background: $baseRed 0% 0% no-repeat padding-box;
      color: $baseWhite;
      font-weight: 600;
    }
  }
}

//project details
.project-details-wrapper {
  font-size: $font-size-base;
  color: $baseWhite;

  .title {
    color: $baseRed;

    .anticon-close {
      color: $baseWhite;
      cursor: pointer;
    }
  }
}

//other Details
.other-details {
  .detail-rows {
    text-align: left !important;
    margin-bottom: 15px;
  }
}

// tab
.custom-tab {
  font-size: $font-size-base;

  .ant-tabs-nav {
    background: $table-bg-white 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 6px;

    &::before {
      border-bottom: none;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    padding: 5px 25px;
    border-radius: 30px;
    font-size: $font-size-lg;
    color: $baseRed;
    font-weight: 500;
  }

  .ant-tabs-tab-active {
    background: $baseRed;

    .ant-tabs-tab-btn {
      color: $baseWhite;
      font-weight: 500;
    }
  }

  .ant-tabs-tab-btn:active {
    //instead of deepRed
    color: $baseWhite;
  }
}

//divider
.custom-line {
  width: 100%;
  height: 1px;
  background: $Grey;
}

.border-right {
  border-right: 1px solid $Grey;
}

.pipeline-modal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    border-color: $light-theme-border-color;
  }

  .ant-select-arrow,
  .ant-select-selection-item {
    color: $baseRed;
  }

  .ant-form {
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $baseRed;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $baseRed;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $baseRed;
    border-color: $baseRed;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $baseRed;
  }

  .ant-input[disabled] {
    color: white;
  }
}

.pipeline-btn {
  display: inline-flex;
  position: absolute;
  right: 60px;

  .cancel-btn {
    color: #fff;
    border-radius: 25px;
    border: 1px solid $baseWhite;
    font-weight: 500;
    transition: 0.3s linear;
  }

  .submit-btn {
    background: $baseRed;
    color: #fff;
    border-radius: 25px;
    border: 1px solid $baseRed;
    font-weight: 500;
    transition: 0.3s linear;
  }
}

.custom-fill {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;

  .new-pipeline {
    position: relative;
    margin: 0 0 0 auto;
    padding-right: 20px;
    text-align: right;
  }

  .weldNumber {
    margin: 0 0 0 auto;

    .ant-btn {
      background: $baseRed;
      border-radius: 25px;
      font-weight: 500;
      transition: 0.2s linear;
      border: 1px solid $baseRed;

      &:hover {
        border: 1px solid $baseRed;
        background: transparent !important;
        color: $baseRed;
      }

      &:focus,
      &:active {
        color: $baseWhite;
      }
    }
  }

  .add-new-pipeline {
    // position: absolute;
    // right: 36%;
    cursor: pointer;
    transition: 0.2s linear;
    font-size: $font-size-base;

    &:hover {
      color: $baseRed;
    }

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }
}

//card
.custom-card {
  background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  padding: 15px;
}

.card-height {
  height: 96px;
}

.theme-color {
  color: $baseRed !important;
}

.create-project {
  background: $baseRed;
  color: $baseWhite;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  padding: 15px;
  margin-right: 16px;
  // margin-bottom: 16px;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  cursor: pointer;
}

.small-text {
  font-size: 13px;
  color: $text-color-white;
}

//scroll bar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #6c757d;
  outline: 1px solid rgb(46, 46, 46);
  border-radius: 10px;
}

// typo
h4.ant-typography {
  font-weight: 500;
}

//modal
.scroll-table {
  height: calc(100vh - 300px);
  overflow: auto;
}

.ant-modal-close {
  display: none;
}

.ant-modal-content {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 14px;
  padding: 2rem;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 30px;
  overflow: hidden;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $table-bg-white;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .ant-modal-content {
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    background-color: rgba(0, 0, 0, 0);
  }
}

.ant-modal-header {
  background: transparent;
  padding: 0 0 16px 0;
  border-color: $Grey;

  .ant-modal-title {
    color: $baseRed;
    font-weight: bold;
  }
}

.align-bottom {
  vertical-align: bottom !important;
}

.hidden-view-btn {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 0;
  height: 100%;
}

.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table {
    .ant-table-thead > tr > th {
      border-top: 1px solid #707070;
      text-align: center;
      font-size: $font-size-base;
      font-weight: bold;
      padding: 10px;
      color: $baseRed;
    }

    .ant-table-tbody > tr > td {
      text-align: center;
      padding: 7px;
    }

    .sequenceNodata-table {
      padding: 50px;
      span {
        opacity: 0.5;
      }
    }
    .Sequence-btn {
      margin-top: 25px;
      button span {
        opacity: inherit;
      }
    }
  }
  .ant-table-container {
    height: 96%;
  }
}

//chart
.chart-wrapper {
  > * {
    height: 100% !important;
  }
}

//@at-root
.edit-session {
  height: 32px;
  align-items: center;

  .ant-picker {
    background: $input-bg !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    height: 32px;
  }

  .ant-btn-primary {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 45px;
  }

  .ant-input {
    max-width: 150px;
  }

  .ant-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ant-input-group-compact {
    .ant-input {
      height: 32px;
    }
  }

  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  .ant-input-group.ant-input-group-compact > *:last-child {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}

.ant-input {
  border: none;
  background: $input-bg !important;
  border-radius: 20px;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
  border: none !important;
  box-shadow: none;
}

.ant-btn-primary {
  height: 32px;
  background: $baseRed;
  color: $baseWhite;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background: $baseRed;
    color: $baseBlack;
  }
}

.rTableCell {
  text-align: left;
}

// .welds-modal{
//   .ant-modal-body{
//     height:76vh;
//     overflow: auto;
//   }
// }
#components-form-demo-control-ref .ant-btn {
  margin-right: 8px;
}

.quality-modal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    border-color: $light-theme-border-color;
    background: $input-bg;
    height: 38px;
    align-items: center;
  }

  .ant-select-arrow,
  .ant-select-selection-item {
    color: $baseRed;
  }

  .ant-form {
    .ant-form-item-label {
      text-align: left;
    }
  }
  span.ant-select-selection-item {
    top: 0px;
  }
  .firstspread {
    span.ant-select-selection-item {
      margin-bottom: 6px;
    }
  }
}

.ant-modal-body {
  padding: 24px 0;
}

.ant-picker-focused,
.ant-picker {
  background: $input-bg;
  border-width: 1px;
  border-style: solid;
  border-color: $light-theme-border-color;
  box-shadow: none;
  height: 38px;
  border-radius: 20px;
  width: 100%;
}

.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #a61d24;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: none !important;
}

.search-container {
  height: 40px;

  .ant-btn {
    height: 30px;
    min-width: 30px !important;

    .anticon {
      position: relative;
      bottom: 4px;
    }
  }
}

.search-wrapper {
  max-width: 380px;
  padding-left: 30px;

  .ant-input {
    height: 32px;
    border-radius: 0;
  }

  .ant-input-search {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: none;
    background: $input-bg !important;

    .ant-input {
      background: transparent !important;
    }
  }

  .ant-input-search-button {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
  }
}

.search-top {
  border: none;
  color: $baseWhite;
  background: $baseRed;

  // border-top-right-radius:20px;
  // border-bottom-right-radius:20px;
  // border-top-left-radius:0px;
  // border-bottom-left-radius:0px;
  &:hover,
  &:focus,
  &:active {
    color: $baseWhite;
    background: $baseRed;
  }

  .anticon-search {
    bottom: 3px !important;
  }

  .ant-input-search-button {
    height: 32px;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: $baseRed;
  }
}

.report-table {
  .ant-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nowrap {
    white-space: nowrap;
  }

  .ant-table-thead {
    position: sticky;
    top: -1px;
    z-index: 3;
    background: rgb(36, 28, 28);
    background: linear-gradient(
      90deg,
      rgba(36, 28, 28, 1) 0%,
      rgba(58, 47, 34, 1) 100%
    );

    tr:first-child th:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.data-listing-table {
  overflow: auto;
  max-height: calc(100vh - 400px);
}

// Login
.login-bg {
  background: url(../assets/loginbg.png);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  height: 100vh;

  &::before {
    content: "";
    background: transparent
      linear-gradient(180deg, #000000b0 0%, #0000001f 100%) 0% 0% no-repeat
      padding-box;
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    right: 0;
  }

  .logo_main {
    position: absolute;
    left: 1.5rem;
    width: 160px;
  }
}

.login_copy {
  position: absolute;
  color: #80808094;
  font-size: 14px;
  font-weight: 500;
  bottom: 10px;
  text-align: center;

  a {
    color: #80808094;
  }
}

.reset-form {
  .ant-input-password,
  .login-form-button {
    width: 96% !important;
  }
}

.signup-form {
  .ant-input {
    width: 100% !important;
  }

  .ant-input-password {
    width: 100% !important;
    margin: 0px 4px;
  }

  .login-form-button {
    height: 50px !important;
    width: 100% !important;
  }
}

.resend-link {
  width: 50vw !important;

  .resendLink {
    font-size: 14px !important;
    border: 0 !important;
    margin: 0 !important;
    color: $baseRed !important;
    padding-top: 0 !important;
    padding-left: 3px !important;
    margin-left: 5px !important;
    font-weight: 500 !important;
    background: none !important;
  }
}

.login-form {
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100%;

  h1 {
    color: $baseWhite;
    font-weight: 700;
    font-size: 30px;
  }

  .login-form-forgot {
    color: $baseWhite;
    text-align: left;
    font-size: 14px;
    padding: 6px 36px;
    cursor: pointer;
    position: relative;
    top: 0;

    &:hover {
      color: $baseRed;
    }
  }

  .signup_link {
    justify-content: center;
    display: grid !important;

    .login-form-signup {
      color: $baseRed;
      font-size: 16px;
      padding: 6px 36px;
      position: relative;
      top: 0;
      font-weight: 500;

      &:hover {
        color: #e95b6f;
      }
    }

    .login-form-signup_text {
      color: $text-color-white;
      font-size: 14px;
      padding: 6px 36px;
      position: relative;
      top: 0;
    }
  }

  .ant-input {
    height: 50px;
    padding: 6.5px 18px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
    border: 0;
    margin: 4px;
    width: 86%;
    color: #ffffffb5;
    font-size: 16px;

    &:hover,
    &:focus-visible {
      outline: none;
    }
  }

  .login-form-button {
    cursor: pointer;
    width: 86%;
    margin: 10px;
    height: 46px;
    padding: 6.5px 18px;
    border-radius: 30px;
    background: #ffffff00;
    border: 1px solid $baseRed;
    margin: 4px;
    font-weight: 600;
    color: white;
    font-size: 16px;

    &.ant-btn:hover,
    &.ant-btn:focus,
    &.ant-btn:active {
      color: $baseWhite;
      border-color: $baseRed;
      background: $baseRed;
      transition: 0.5s linear;
    }
  }

  .resendBtn {
    width: 37% !important;
    font-size: 14px !important;
    height: 20px !important;
    border: 0 !important;
    margin: 0 !important;
    color: $baseRed !important;
    padding-top: 0 !important;
    padding-left: 5px !important;
    margin-left: 10px !important;
    font-weight: 500 !important;
    background: none !important;

    &:hover {
      border: 0 !important;
      color: $baseWhite !important;
    }
  }

  .resert-psw {
    a,
    p {
      font-size: $font-size-lg;
    }
  }

  .ant-input-password {
    border: 0;
    width: 86%;
    border-radius: 25px;
    padding: 0;

    .ant-input {
      width: 100%;
      margin: 0;
      padding-left: 20px;
      padding-right: 40px;
    }

    .ant-input-suffix {
      position: absolute;
      right: 20px;
      top: 18px;

      .ant-icon {
        font-size: 16px;
        color: white;
      }
    }
  }
}

.top-bar-input {
  height: 32px;
  border: none !important;
  background: $input-bg;
  border-radius: 20px;
  min-width: 150px;
  padding: 0 15px;

  .ant-input {
    border: none !important;
    background: transparent !important;
    border-radius: 0 !important;
  }

  .anticon-close {
    font-size: 12px;
  }
}

.ant-form-item-explain-error {
  font-size: 13px;
  color: $baseRed;
  display: flex;
  text-align: left;
  padding-left: 3%;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.very-number {
  .ant-input {
    width: 45px;
    border-radius: 15px;
  }
}

//coming soon
.coming-soon {
  background: $card-bg-white;
  width: 540px;
  padding: 40px 20px;
  border-radius: 14px;
  text-align: center;

  position: absolute;
  top: 30%;

  h1 {
    font-size: 50px;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700;
    opacity: 0.5;
  }

  .dot {
    color: $baseRed;
  }

  p {
    text-align: center;
    margin: 18px;
    font-weight: normal;
  }
}

//loading
.ant-spin {
  color: $baseWhite;
}

.ant-spin-dot-item {
  background-color: $baseRed;
}

//button
.refresh {
  background: transparent;
  color: #fff;
  border-color: #fff;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    border-color: #fff;
  }
}

.input-close {
  right: 15px;
  position: absolute;
  height: 20px;
  color: #ccc3c3;
}

.search-input {
  padding-right: 40px !important;
}

.user_photo {
  font-size: 46px;
  background: $text-color-white;
  border-radius: 50px;
  padding: 16px;
  color: $baseRed;
}

.change_profile_password {
  font-size: 16px;
  color: $baseRed;
  font-weight: 500;
  cursor: pointer;
}

.profilePassowrd_form {
  margin-top: 16px;

  .ant-input-password {
    border: 0;
    border-radius: 25px;
    padding: 0;
    width: 86%;
    height: 50px;

    .ant-input {
      margin: 0;
      padding-left: 20px;
      padding-right: 40px;
      width: 100%;
    }

    .ant-input-suffix {
      position: absolute;
      right: 20px;
      top: 16px;
    }
  }

  .profile_password_button {
    width: 86%;
    background: $baseRed;
    color: white;
    font-weight: 500;
    border: 1px solid $baseRed;
    transition: 0.3s linear;

    &:hover {
      background: transparent !important;
    }
  }
}

.close_password {
  background: $baseRed;
  border-radius: 25px;
  padding: 4px;
  font-size: 10px;
  position: relative;
  left: 6px;
  bottom: 4px;
  color: white;
}

.profilePassowrd_form {
  overflow: hidden;
  transition: height 1s, width 1s, padding 1s, visibility 1s,
    opacity 0.5s ease-out;
}

/*Making No-background changes for auto complete (auto select input) username and answword*/

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

@media (max-width: 1400px) {
  //silder
  .flexslider {
    .rec-arrow {
      height: 96px;
    }
  }

  .slides {
    height: 96px;
  }

  .create-project {
    height: 96px;
  }

  // tab
  .custom-tab {
    .ant-tabs-tab {
      font-size: $font-size-base;
    }
  }

  .tab-content-title {
    h4 {
      font-size: $font-size-lg;
    }

    span {
      line-height: normal;
      font-size: small;
    }
  }

  .custom-card {
    h2 {
      font-size: $font-size-xxl;
    }

    &.card-height {
      height: 84px;
    }
  }
}

@media (max-width: 1200px) {
  //silder
  .flexslider {
    .rec-arrow {
      height: 80px;
    }
  }

  .slides {
    height: 80px;
  }

  .create-project {
    height: 80px;
  }

  // tab
  .tab-content-title {
    h4 {
      font-size: $font-size-base;
    }
  }

  .custom-card {
    padding: 10px;

    h2 {
      font-size: $font-size-xl;
    }

    span {
      font-size: 12px;
    }

    &.card-height {
      height: 75px;
    }
  }
}

.resetPassword {
  font-size: $font-size-lg;
  color: $baseWhite;
}

//projectListHeader

.client-select {
  .ant-select-selection-item {
    position: absolute !important;
    padding: 5px !important;
    color: white;
  }

  .ant-select-selector {
    border: none !important;
    border-radius: 14px;
    background: $input-bg !important;
    height: 38px !important;

    &:focus-visible {
      outline: none !important;
    }

    .ant-select-selection-placeholder {
      position: absolute;
      top: 4px;
    }

    .ant-select-selection-search {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: "tnum", "tnum";
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 0;
      color: rgba(255, 255, 255, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: transparent;
      background-image: none;
      border-radius: 2px;
      transition: all 0.3s;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .ant-select-selection-search-input {
      padding: 6.5px 0px !important;
      font-size: 16px;
      height: 32px !important;
      color: $baseWhite;
    }
  }
}

//carousal pagination

.rec-pagination {
  padding: 2px 10px;
  background: #58484a;
  position: absolute;
  top: 4px;
  right: 110px;
  border-radius: 15px;

  .rec-dot_active {
    background-color: #dc35454d !important;
    box-shadow: 0 0 1px 3px #dc3545 !important;
  }

  .rec-dot_active:hover,
  .rec-dot_active:focus {
    cursor: pointer;
    box-shadow: 0 0 1px 3px #dc3545 !important;
  }

  .rec-dot {
    background-color: transparent;
    box-shadow: 0 0 1px 2px #00000080;
  }

  .rec-dot:hover,
  .rec-dot:focus {
    cursor: pointer;
    box-shadow: 0 0 1px 3px #dc3545;
  }
}

.date-picker {
  .ant-picker {
    background: $input-bg !important;
  }
}

// add test in ITP
.add-test {
  position: relative;
  left: 20px;
  bottom: 10px;
  font-weight: 500;

  .new-test {
    position: relative;
    margin: 0 0 0 auto;
    padding-right: 20px;
  }

  .add-new-test {
    // position: absolute;
    // right: 36%;
    cursor: pointer;
    transition: 0.2s linear;
    font-size: $font-size-base;

    &:hover {
      color: $baseRed;
    }

    .icon-20 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }
}

.itp-overflow {
  overflow-y: hidden !important;

  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.itp_carousal {
  .rec-arrow-right:hover:enabled {
    color: #fff !important;
    background-color: $baseRed !important;
    box-shadow: 0 0 2px 0 #333 !important;
  }

  .rec-arrow-left:hover:enabled {
    color: #fff !important;
    background-color: $baseRed !important;
    box-shadow: 0 0 2px 0 #333 !important;
  }
}

.statement_work,
.logDetails_table {
  .ant-table-placeholder {
    background: transparent !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      background: transparent !important;
    }

    .ant-table-cell {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: transparent !important;

      // z-index: 1000;
      &:hover {
        background: transparent !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: #39201d !important;
  }

  .ant-table-tbody tr.ant-table-row:nth-child(odd) {
    background: #3f0a0c !important;
  }

  .ant-table-tbody {
    tr.ant-table-row {
      border-radius: 30px;

      // &:nth-child(odd) {
      //   background: #ef314c26 !important;
      // }
    }
  }

  tr:nth-child(even) {
    td:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #39201d !important;
    }

    td:first-child {
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
    }

    &:hover > td:last-child {
      background: #39201d !important;
    }
  }

  tr:nth-child(odd) {
    td:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #3f0a0c !important;
    }

    td:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover > td:last-child {
      background: #3f0a0c !important;
    }
  }

  tr:first-child {
    td:last-child {
      background-color: transparent !important;

      &:hover {
        background: transparent !important;
      }
    }

    &:hover > td:last-child {
      background: transparent !important;
    }
  }
}

.logDetails_table {
  tr:nth-child(even) {
    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #39201d !important;

      &:hover {
        background: #39201d !important;
      }
    }
  }

  tr:nth-child(odd) {
    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #3f0a0c !important;

      &:hover {
        background: #3f0a0c !important;
      }
    }
  }

  tr.ant-table-row:hover:nth-child(odd) {
    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #3f0a0c !important;
    }
  }

  tr.ant-table-row:hover:nth-child(even) {
    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #39201d !important;
    }
  }
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after {
  box-shadow: none !important;
}

.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: none !important;
}

// .log-details {
.logTabel-modal {
  width: 90% !important;
}

.equipTable-modal {
  width: 80% !important;
}

.maitenance-modal {
  width: 60% !important;

  .add_new_maintenance {
    .ant-form-item {
      margin: 0;
    }

    .addIcon {
      padding: 6px;
      margin: 3px;
      background: $baseRed;
      border-radius: 25px;
      cursor: pointer;
      transition: 0.2s linear;
      border: 1px solid $baseRed;
      margin-right: 6px;

      &:hover {
        background: transparent;
      }
    }

    .cancelIcon {
      padding: 6px;
      margin: 3px;
      background: $light-theme-text-color;
      border-radius: 25px;
      cursor: pointer;
      transition: 0.2s linear;
      border: 1px solid $light-theme-text-color;

      &:hover {
        background: transparent;
      }
    }
  }

  .add_maintenance {
    text-align: end;

    .child {
      padding: 3px 10px;
      background: $baseRed;
      border-radius: 25px;
      color: white;
      cursor: pointer;

      .anticon {
        position: relative;
        bottom: 3px;
      }
    }
  }
}

.logTabel-modal,
.equipTable-modal {
  overflow: hidden;
  border-radius: 25px;

  .ant-modal-header .ant-modal-title {
    font-weight: 600;
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-modal-body {
    padding-bottom: 0 !important;
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: #622d35 !important;
        border-bottom: none !important;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 400 !important;
        color: $baseRed;
        padding: 10px;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: none;
  }

  .ant-table-tbody tr.ant-table-row td {
    padding: 10px;
  }

  .like,
  .unlike,
  .gradeOut,
  .pictureIcon,
  .gradeOutPictureIcon {
    border-radius: 25px;
    border: 1px solid;
    padding: 5px;
  }

  .like {
    color: #7eff12;
    background: $baseBlack;
  }

  .unlike {
    color: #ff0000;
    background: $baseBlack;
  }

  .gradeOut {
    color: $Grey;
    background: $baseBlack;
  }

  .pictureIcon {
    color: $baseRed;
    background: $baseBlack;
  }

  .gradeOutPictureIcon {
    color: $Grey;
    background: $baseBlack;
  }

  .pending {
    border-radius: 25px;
    padding: 4px;
    color: #d29f11;
    background: $baseBlack;
    border: 1px solid;
  }
}

// }
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  font-size: 14px !important;
}

.inspection-logs {
  position: relative;
  margin: 0 0 0 auto;
  padding-right: 20px;
  display: inline-flex;
  align-items: center;

  .log-filter {
    font-size: 20px;
    margin: 0 18px;
    cursor: pointer;
  }

  .ant-btn {
    background: $baseRed;
    color: white;
    cursor: pointer;
    border-radius: 25px;
    font-weight: 500;
    transition: 0.2s linear;
    border: 1px solid $baseRed;

    &:hover {
      border: 1px solid $baseRed;
      background: transparent !important;
      color: $baseRed;
    }

    &:focus,
    &:active {
      color: $baseWhite;
    }
  }
}

.team_tabs {
  .btn_left {
    border-radius: 25px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn_right {
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .btn_left,
  .btn_right {
    border: 1px solid $table-bg-white;
    transition: 0.2s linear;
    background: $table-bg-white 0% 0% no-repeat padding-box;
    color: $baseRed;
    height: 40px;

    &:active,
    &:hover,
    &:focus {
      color: White;
    }
  }

  .btnActive {
    color: $baseWhite !important;
    background: $baseRed;
    border: 1px solid $baseRed;
  }
}

.new-project-team {
  width: auto;
  margin: 0 0 0 auto;

  .add-new-team {
    cursor: pointer;
    transition: 0.2s linear;
    font-size: 14px;

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }
}

.addTeamDetails {
  .pipeline-table {
    padding-bottom: 10px;
  }

  .new-team {
    text-align: left;
    padding-left: 20px;
  }

  .add-new-team {
    cursor: pointer;
    transition: 0.2s linear;
    font-size: $font-size-base;

    &:hover {
      color: $baseRed;
    }

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }

  .equipPaginationTable {
    .ant-table-pagination.ant-pagination {
      padding: 16px;
      margin: 0;
      background: transparent;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      left: 50;
      right: 0;
      bottom: -55px;
    }

    .ant-pagination-item-active:hover,
    .ant-pagination-item-active,
    .ant-pagination-item:hover,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $baseRed;
    }

    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-item:hover a {
      color: $baseRed;
    }
  }
}

.ant-pagination-item-active {
  border-color: $baseRed;
  border-width: 2px;
}

.ant-pagination-item-active:hover {
  border-color: $baseRed;
}
// To remove lines (border) for table head
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background: none;
}

.inventory {
  height: calc(100vh - 350px) !important;
}

.report-radio {
  .ant-radio-inner::after {
    background-color: $baseRed;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $baseRed;
  }

  .ant-radio:hover,
  .ant-radio-inner {
    border-color: $baseRed !important;
  }

  .ant-form input[type="radio"]:focus {
    outline: none !important;
  }
}

.filter-logs {
  .filter-none {
    height: 32px;
    border: none !important;
    background: $input-bg;
    border-radius: 20px;
    min-width: 200px;
    padding: 7px 15px;
    position: relative;
    top: 6px;
    font-weight: 500;
  }

  .top-bar-input {
    height: 32px;
    border: none !important;
    background: $input-bg;
    border-radius: 20px;
    min-width: 220px;
    padding: 0 15px;

    .ant-input {
      border: none !important;
      background: transparent !important;
      // height: 32px;
      border-radius: 0 !important;
    }

    .anticon-close {
      font-size: 12px;
    }
  }
}

.notfound {
  color: #177ddc;
  font-size: "16px";
  font-weight: 500;

  &:hover {
    color: $baseRed !important;
  }
}

.ant-input-number {
  border-radius: 25px;
  width: 100%;
  background: $input-bg;
  border-color: transparent;
  box-shadow: none;
  height: 38px;

  &:hover {
    border-color: transparent;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.log-calender {
  .ant-picker-calendar-header {
    background: #281a1b;
  }

  .ant-picker-calendar-full .ant-picker-panel {
    background: #281a1b;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value {
    color: $baseWhite;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today {
    background: #330f14;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: $baseRed;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 25px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $baseRed !important;
    border-right-width: 1px !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled)
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ant-radio-button-wrapper:first-child {
    border-right-color: $baseRed;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):first-child {
    border-color: $baseRed;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: $baseRed;
    background: transparent;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    color: $baseRed;
    border-color: $baseRed;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: $baseRed;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: $baseRed;
  }

  .ant-radio-button-wrapper:hover {
    color: $baseRed;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):focus-within {
    box-shadow: none !important;
  }

  .ant-radio-button-wrapper:focus-within {
    box-shadow: none !important;
  }

  .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.log-calender {
  .events,
  .notes-month {
    background: $baseRed;
    border-radius: 3px;
    padding: 0 5px;
    font-weight: 500;
  }

  .ant-picker-calendar-header {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 500;
    border-bottom: 1px solid $Grey;
    // justify-content: center;
    padding-right: 10px;
  }

  .ant-picker-calendar-full .ant-picker-panel {
    position: relative;
    top: 50px;
  }

  .ant-picker-calendar {
    overflow-y: scroll;
    height: calc(100vh - 420px);
    // box-shadow: 0 0 8px 0px #141414;
    background: transparent;
  }

  .ant-select-dropdown {
    height: 220px;
  }
}

.log-select-pipeline {
  position: fixed;
  z-index: 1000;
  margin: 8px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 25px;
  }
}

.itp-weld {
  position: relative;
  top: 3px;
  left: 10px;

  .ant-form-item {
    margin: 0 !important;

    .ant-input-lg {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .ant-input {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .weld-icon svg {
    border-radius: 25px;
    margin: 3px;
    padding: 6px;
    font-size: 28px;
    cursor: pointer;
    transition: 0.3s linear;
  }

  .add svg {
    background: $baseRed;
    border: 1px solid $baseRed;

    &:hover {
      background: #ef314c69;
      border: 1px solid #ef314c69;
    }
  }

  .close svg {
    background: transparent;
    border: 1px solid #323232;

    &:hover {
      background: #323232;
      border: 1px solid #323232;
    }
  }
}

.itp-select {
  .ant-select {
    // width: 170px;
    margin: 2px 0px 0;
  }

  .ant-select-selection-item {
    width: 80%;
    font-weight: 500;
    font-size: 14px;
  }

  .ant-select-selector {
    border-radius: 25px !important;
  }

  .ant-form-item {
    margin: 0 !important;
  }

  .ant-select-arrow {
    padding-top: 8px !important;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
  }
}

.ant-select-selection-item {
  width: 80%;
}

.station_name {
  display: inline-flex;
  height: 26px;

  .station_name_ {
    font-weight: 700;
    font-size: 16px;
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .edit_station_name {
    font-size: 16px;
    color: $baseRed;
    position: relative;
    // left: 10px;
    top: 6px;
    cursor: pointer;
  }

  .edit_station_input {
    width: 60% !important;
  }

  .edit_close_icon,
  .edit_apply_icon {
    background: #311f1e;
    border-radius: 25px;
    font-size: 10px;
    padding: 9px;
    position: relative;
    bottom: 3px;
    transition: 0.2s linear;
  }

  .edit_close_icon {
    &:hover {
      background: #212529;
    }
  }

  .edit_apply_icon {
    left: 5px;
    background: $baseRed;

    &:hover {
      background: #ef314ca8;
    }
  }
}

.test_text {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.station_container {
  height: 120px;
  position: relative;
  cursor: pointer;
}

.delete_sow_station {
  position: absolute;
  top: 2px;
  bottom: 20px;
  left: 14%;
  right: 14%;
  height: 100%;
  width: 70%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #ef314cb5;
  border-radius: 4px;
}

.station_container:hover .delete_sow_station {
  opacity: 1;
}

.delete_station_icon {
  color: $baseRed;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  transition: 0.3s linear;
  border-radius: 25px;
  padding: 11px;
  background-color: whitesmoke;

  &:hover {
    color: $baseWhite;
    background: #111b26ad;
  }
}

.no_image {
  color: $baseRed;
  background: #281a1b;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 3px;
  padding: 6px 20px;
  font-weight: 600;
  /* color: white; */
  // background: #ef314cb5;
}

.delete_station_list_icon {
  color: $baseRed;
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 6px;
  background: #2b2021;
  border-radius: 25px;
  padding: 4px;
  transition: 0.3s linear;

  &:hover {
    background: #dee2e6;
  }
}

.ant-select-selection-item {
  .anticon-delete {
    display: none;
  }
}

.calender_header {
  z-index: 1000;
  position: absolute;
  right: 0;
  left: 0;
  background: #281a1b;
  padding: 6px;

  .ant-form-item {
    margin: 0;
  }

  .year,
  .month {
    .ant-select-selection-item {
      left: 0;
      top: 2px;
    }

    .ant-select-selection-placeholder {
      position: absolute;
      left: 12px;
      top: 8px;
    }
  }

  .calender_button_header {
    text-align: left;
    top: 2px;

    .calender_btn {
      border-radius: 25px;
      background: $baseRed;
      height: 36px;
      border: none;
      width: 50%;
      font-weight: 500;
      transition: 0.3s linear;
      color: $baseWhite;

      &:hover {
        color: $baseWhite;
        background: $light-theme-text-color;
      }
    }
  }
}

.calheight {
  overflow: hidden !important;
}

.view {
  .ant-picker-panel {
    display: block;
  }
}

.hide {
  .ant-picker-panel {
    display: none;
  }
}

.calTable {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.log-table {
  ::-webkit-scrollbar-thumb {
    background-color: rgb(122, 122, 122);
    outline: 1px solid rgb(92, 92, 92);
    border-radius: 10px;

    // background-color: #ef314cba;
    //   outline: #ef314cba;
    //   border-radius: 10px;
  }

  .ant-table-body {
    position: relative;
    top: 10px;
  }

  .ant-table-pagination.ant-pagination {
    position: absolute;
    padding: 10px;
    z-index: 10;
    bottom: -100px;
  }

  .ant-pagination-item-active:hover,
  .ant-pagination-item-active,
  .ant-pagination-item:hover,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $baseRed;
  }

  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-item:hover a {
    color: $baseRed;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $baseRed;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .anticon-left,
    .anticon-right {
      position: relative;
      bottom: 3px;
    }
  }
}

.dated {
  border-radius: 25px;
  background: $baseRed;
  padding: 3px 10px;
  position: absolute;
  top: 30px;
  right: 36px;
}

.verificationCode {
  input {
    height: 50px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    border: 0;
    margin-right: 10px;
    margin-left: 10px;
    width: 30px !important;

    &:focus-visible {
      outline: 2px solid $baseRed;
    }
  }
}

.ant-form-item-feedback-icon {
  position: relative;
  bottom: 4px;
}

.profile_text {
  font-size: 16px;
  color: $text-color-white;
}

.equip_table {
  .ant-table-tbody {
    tr:nth-child(odd) {
      td:first-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      td:last-child {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }
}

.equipment_logs {
  .equip_tabs {
    text-align: center;
    margin-bottom: 20px;
    background: $table-bg-white 0% 0% no-repeat padding-box;
    border-radius: 25px;
    padding: 8px;
  }

  .ant-btn {
    border-radius: 25px;
    font-weight: 500;
    transition: 0.2s linear;
    border: transparent;
    font-weight: 600;
    color: $baseWhite;

    &:focus,
    &:active {
      color: $baseWhite;
    }
  }

  .active {
    border: 1px solid $baseRed;
    background: $baseRed !important;
  }

  .log-table .ant-table-pagination.ant-pagination {
    bottom: -55;
  }
}

.equip_note {
  position: absolute;
  right: 0;
  font-size: 10px;
  bottom: 0;
}

.calenderView {
  position: absolute;
  top: 20px;
  right: 36px;

  .ant-picker-suffix {
    color: $baseRed;
  }
}

.calenderRangeView {
  position: absolute;
  top: 0;
  right: 0;

  .ant-picker-suffix {
    color: $baseRed;
  }
}

.weekDate {
  position: absolute;
  top: 30px;
  right: 200px;
  background: $baseRed;
  border-radius: 25px;
  padding: 0 12px;
}

.performedDate {
  background: $baseRed;
  padding: 3px 12px;
  border-radius: 25px;
  position: relative;
  top: -10px;
}

.maintenance-select {
  .ant-select-selector {
    border-radius: 25px !important;
    border: none !important;
    background: $input-bg !important;
    height: 100% !important;
  }

  .ant-select-selection-overflow {
    padding: 6px 12px 6px 0px;

    .ant-select-selection-item {
      border-radius: 25px;
      color: $baseWhite;
      width: 96%;
      padding: 0px 10px;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: $baseRed;
}

.imagePreview {
  .ant-image-img {
    width: 100%;
    height: 200px;
    vertical-align: middle;
    object-fit: contain;
    border-radius: 3px;
  }

  a {
    position: relative;
    top: 10px;
    display: flex;
    justify-content: center;
    width: 200px;

    .ant-typography {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .anticon {
      font-size: 20px;
      border-radius: 25px;
      border: 1px solid;
      margin: 0 10px;
      color: $baseRed;
      background: $baseBlack;

      svg {
        padding: 3px;
      }
    }
  }
}

.admin {
  height: calc(100vh - 40px);

  header {
    .ant-typography {
      font-size: 20px;
    }
  }

  .ant-col {
    padding: 20px;
  }
}

.equipmentsPage {
  .searchEquip {
    margin: 0 20px;

    .ant-input-affix-wrapper {
      background: $input-bg !important;
      border-radius: 20px;
      border: none;

      .ant-input {
        background: transparent !important;
        border-radius: 0;
      }
    }
  }
}
//Code Addition - Manjunath
.first-parent {
  display: flex;
  .left-div {
    margin-right: 23px;
    height: 368px;
    background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    border-radius: 14px;
    padding: 8px;
    margin-bottom: 16px;
    .three-buttons {
      box-sizing: content-box;
      max-height: 20%;
      margin-top: 0rem;
      height: 30%;
      display: flex;
      gap: 30%;
      margin: 10% 0 0;
      position: relative;
      bottom: 22px;
      .plus-div {
        flex: 1 1 30%;
      }
      .empty-div {
        box-sizing: border-box;
        max-height: 80%;
        max-width: 80%;
        flex: 1 1 40%;
        position: absolute;
        left: 25%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        bottom: 22px;
      }

      .delete-div {
        flex: 1 1 30%;
      }
    }
    .no-buttons {
      box-sizing: content-box;
      max-height: 20%;
      display: flex;
      gap: 30%;
      margin: 10% 0 0;
      position: relative;
      bottom: 22px;
      margin-top: 55px;
      .plus-div {
        flex: 1 1 30%;
      }
      .empty-div {
        box-sizing: border-box;
        max-height: 80%;
        max-width: 80%;
        flex: 1 1 40%;
        position: absolute;
        left: 25%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        bottom: 22px;
      }

      .delete-div {
        flex: 1 1 30%;
      }
    }
  }
  .right-div {
    flex: 3 3 85%;
    padding: 1% 0;
    position: relative;
    margin-top: 1rem;
    .qr-code-div {
      padding: 0% 0% 0% 0%;
      position: absolute;
      top: -45px;
      right: 40px;
      .form-icon {
        font-size: 22px;
        color: $baseRed;
      }
      .form1-icon {
        font-size: 35px;
        color: $baseRed;
        margin-right: 30px;
      }
      .anticon.anticon-edit {
        font-size: 22px;
        color: $baseRed;
        position: relative;
        right: 40px;
      }
    }

    .ant-form-vertical .ant-form-item-row {
      flex-direction: row;
    }
  }
  .spread-button {
    background-color: $baseRed;
    border-radius: 14px;
    width: 100%;
    border: none;
    color: $baseWhite;
    padding: 4px;
    margin-bottom: 24%;
    box-sizing: border-box;
  }
  .Subspread-button {
    background-color: $baseRed;
    border-radius: 14px;
    width: 100%;
    border: none;
    color: $baseWhite;
    padding: 4px;
    margin-top: -15%;
    box-sizing: border-box;
  }
  .select-drop {
    border-radius: 18px;
    color: $baseRed;
    height: 37px;
    background-color: $input-bg;
    span.ant-select-selection-item {
      color: rgba(255, 255, 255, 0.3);
    }
  }
  .select-drop-disabled {
    border-radius: 18px;
    color: $baseRed;
    height: 37px;
    background-color: $input-bg;
    span.ant-select-selection-item {
      color: $baseWhite;
    }
  }
  .ant-btn-primary {
    background-color: $baseRed;
    border-radius: 19px;
    font-weight: bold;
    margin: 0px 30px;
  }
  .ant-btn-secondary {
    background-color: $baseWhite-transparant;
    border-radius: 19px;
    font-weight: bold;
    border: 1px solid;
    margin: 0px 25px;
  }
  .ant-select-selection-item {
    color: $baseWhite;
  }
  .ant-select-selection-item {
    color: $baseWhite;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    // color: $baseWhite-transparant;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 3px;
  }
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    background-color: transparent;
    border-color: $baseRed !important;
    border-radius: 18px;
  }
  // .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  //   color: $baseWhite;
  // }
  .ant-tree-list-holder-inner {
    overflow: scroll;
    height: 280px;
    overflow-x: hidden;
    width: 195px;
    scrollbar-width: none;
  }
  .ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
    display: none;
  }
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    line-height: 2.2;
  }
}

.tooltip-parent {
  position: relative;
  .tooltip-text {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: none;
    color: $baseRed;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .tooltip-text-active {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: none;
    color: $baseWhite;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .tooltip-text-bottom {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: none;
    color: $baseRed;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .tooltip-text-active-bottom {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: none;
    color: $baseWhite;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .tooltip-text::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
  .tooltip-text-active {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
}

.tooltip-parent:hover {
  .tooltip-text {
    top: -90%;
    line-height: 100%;
    font-size: 11px;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
  .tooltip-text-active {
    top: -90%;
    line-height: 100%;
    font-size: 11px;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
  .tooltip-text-bottom {
    top: 110%;
    line-height: 100%;
    font-size: 11px;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
  .tooltip-text-active-bottom {
    top: 110%;
    line-height: 100%;
    font-size: 11px;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
}

//display tooltip
.tooltip-display-parent {
  position: relative;
  .tooltip-display-text {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: none;
    color: $baseRed;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .tooltip-display-text-active {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: none;
    color: $baseWhite;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .tooltip-display-text::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
  .tooltip-display-text-active {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
}

.tooltip-display-parent:hover {
  .tooltip-display-text {
    top: 110%;
    line-height: 100%;
    font-size: 11px;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
  .tooltip-display-text-active {
    top: 110%;
    line-height: 100%;
    font-size: 11px;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
}

.qr-config-details-parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .qr-code {
    border: 4px solid $baseWhite;
    height: 204px;
    width: 204px;
  }
}

.qr-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .qr-send-btn {
    .ant-btn {
      width: 15px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      .anticon {
        color: $baseRed;
      }
    }
    .email-button {
      position: relative;
    }
    .email-button::after {
      animation: none !important;
      margin-top: 28px;
      width: 300px;
      content: attr(data-tooltip);
      position: absolute;
      font-size: 12px;
      bottom: -45%;
      left: 50%;
      transform: translateX(-50%);
      color: $baseRed;
      padding: 5px;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
      z-index: 1;
    }
    .email-button:hover::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.no-data-available {
  min-height: 41.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $baseWhite;
}

.pipe-status-tab {
  .pipeStatusHeader {
    color: $baseRed;
  }
  .data {
    color: $baseWhite;
  }
}
.pipe-summary-tab {
  display: flex;
  flex: row nowrap;
  justify-content: space-around;
  padding: 0 5% 1%;
  .header {
    color: $baseRed;
  }
  .data {
    color: $baseWhite;
  }
}

.ant-picker-clear {
  display: block;
  border-radius: 16px;
  font-size: 18px;
  position: absolute;
  right: -2px;
}

.icon-grey {
  color: $Grey;
  font-size: 20px;
}

.email-popup-text {
  button:focus {
    outline: none;
    border-color: $baseWhite-transparant;
  }
  .modal-email-button {
    margin: 10px 0px 10px 5px;
  }
  .modal-email-button:hover {
    border: 1px solid $baseWhite;
  }
  .modal-email-button:active {
    border: 1px solid $baseRed;
  }
  .email-cancel-button {
    padding: 7px;
    width: 12px;
    height: 17px;
    margin-left: -2px;
    margin-top: 0px;
    border: 0;
    .anticon {
      vertical-align: top;
      margin-left: -7px;
      margin-top: -7px;
    }
  }
  .email-cancel-button:hover {
    border: 0;
    .anticon {
      color: $baseRed;
    }
  }
  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
  }

  button.ant-btn.ant-btn-primary.add-email {
    position: relative;
    right: 21px;
    bottom: 13px;
  }
  .email-input-and-send {
    display: flex;
    flex-direction: row;
    .ant-input {
      width: 395px;
      margin-right: 10px;
    }
    .anticon {
      font-size: 30px;
      color: $baseRed;
    }
  }
}

.searchConsumabels {
  margin: 0 20px;

  .ant-input-affix-wrapper {
    background: $input-bg !important;
    border-radius: 20px;
    border: none;

    .ant-input {
      background: transparent !important;
      border-radius: 0;
    }
  }
}

@media (max-height: 760px) {
  .team_resolution {
    height: calc(100vh - 400px) !important;
  }
}

@media screen and (max-width: 1280px) {
  .view_station {
    width: 400px;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .view_station {
    width: 400px;
  }
}

@media (min-width: 1367px) and (max-width: 1600px) {
  .view_station {
    width: 450px;
  }

  .main_station_height {
    height: calc(100vh - 420px) !important;

    .test_height {
      height: calc(100vh - 650px) !important;
    }
  }

  .right-div {
    flex: 3 3 80%;
    padding: 1% 0;
  }
}

@media (min-width: 1601px) and (max-width: 1700px) {
  .view_station {
    width: 400px;
  }

  .right-div {
    flex: 3 3 80%;
    padding: 1% 0;
  }
}

@media (min-width: 1700px) and (max-width: 1870px) {
  .view_station {
    width: 420px !important;
  }

  .right-div {
    flex: 3 3 85%;
    padding: 1% 0;
  }

  .main_station_height {
    height: calc(100vh - 420px) !important;

    .test_height {
      height: calc(100vh - 650px) !important;
    }
  }
}

@media (min-width: 1871px) and (max-width: 2000px) {
  .view_station {
    width: 420px !important;
  }

  .main_station_height {
    height: calc(100vh - 420px) !important;

    .test_height {
      height: calc(100vh - 650px) !important;
    }
  }

  @media (max-height: 760px) {
    .team_resolution {
      height: calc(100vh - 400px) !important;
    }
  }

  .right-div {
    flex: 3 3 85%;
    padding: 1% 0;
  }
  .flexslider-bt {
    .rec-arrow {
      height: 76px;
    }
  }
}

@media (min-width: 2001px) and (max-width: 2460px) {
  .view_station {
    width: 420px !important;
  }

  .main_station_height {
    height: calc(100vh - 420px) !important;

    .test_height {
      height: calc(100vh - 650px) !important;
    }
  }

  .right-div {
    flex: 3 3 85%;
  }
  .flexslider-bt {
    .rec-arrow {
      height: 76px;
    }
  }
}

@media screen and (min-width: 1915px) and (max-width: 2465px) {
  .right-div {
    flex: 3 3 80%;
  }

  .empty-div {
    left: 30%;
  }

  .parent-tree {
    color: $baseWhite;
  }
}

$blink: "blink";

.blinkingDot {
  height: 8px;
  width: 8px;
  // background-color: limegreen;
  border-radius: 50%;
  opacity: 1;
}

.blinking {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  bottom: 6px;
  right: 2px;

  -webkit-animation: 1.4s #{$blink} ease infinite;
  -moz-animation: 1.4s #{$blink} ease infinite;
  -ms-animation: 1.4s #{$blink} ease infinite;
  -o-animation: 1.4s #{$blink} ease infinite;
  animation: 1.4s #{$blink} ease infinite;
}

@keyframes #{$blink} {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes #{$blink} {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes #{$blink} {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes #{$blink} {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes #{$blink} {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.pjoint-table table th {
  white-space: normal !important;
}

.custom-expand-icon {
  position: relative;
  right: 6px;
  top: -3px;
  color: $baseRed;
}

.pdfIcon:hover {
  color: $baseRed;
}

.pdfIcon:hover {
  color: $baseRed;
}

.pdfIconRed {
  color: $baseRed;
  background: $baseBlack;
  border-radius: 25px;
  border: 1.5px solid;
  padding: 5px;
}

.gradepdfIconRed {
  color: $Grey;
  background: $baseBlack;
  border-radius: 25px;
  border: 1.5px solid;
  padding: 5px;
}
button#basic_allowPipeTranslation {
  border: 2px solid $light-theme-border-color;
  height: 25px;
}
button#basic_duplicateSinglePipeEnd {
  border: 2px solid $light-theme-border-color;
  height: 25px;
}

button#basic_allowPipeTranslation.ant-switch.ant-switch-checked {
  background-color: $baseRed;
}

button.ant-switch {
  background-color: $input-bg;
  margin-right: 2px;
}

button.ant-switch.ant-switch-checked {
  background-color: $baseRed;
  margin-right: 2px;
  box-shadow: none;
}

button.ant-switch {
  button#basic_allowPipeTranslation.ant-switch {
    background-color: $input-bg;
  }

  button#basic_duplicateSinglePipeEnd.ant-switch.ant-switch-checked {
    background-color: $baseRed;
  }

  button#basic_duplicateSinglePipeEnd.ant-switch {
    background-color: $input-bg;
  }
  button#basic_duplicateSinglePipeEnd {
    border: 2px solid $light-theme-border-color;
    height: 25px;
  }

  .ant-col-3 {
    max-width: none;
  }
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: $baseWhite;
}

.table-view {
  background: $table-bg-white;
  border-radius: 16px;
  padding: 20px 20px 0px 20px;
  margin-left: 21px;
  padding-left: 0px;
  margin-bottom: 20px;
  .switch-container {
    margin-bottom: 44px;
    display: flex;
    margin-left: 18px;
    margin-top: 6px;
  }
  .form-icon {
    font-size: 20px;
    color: $baseRed;
  }
  .sequenceNodata-table-status {
    padding: 173px;
    position: absolute;
    width: 80%;
    bottom: 17px;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .table-view {
    .sequence-data {
      .container {
        width: 579px;
      }
    }

    .container {
      width: 579px;
      margin-top: -24px;
    }
  }
}

@media (min-width: 1101px) and (max-width: 1200px) {
  .table-view {
    .container {
      width: 687px;
      margin-top: -24px;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .table-view {
    .container {
      width: 815px;
      margin-top: -24px;
    }
  }
}
@media (min-width: 1300px) and (max-width: 1400px) {
  .table-view {
    .container {
      width: 880px;
      margin-top: -24px;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  .table-view {
    .container {
      width: 970px;
      margin-top: -24px;
    }
  }
}

@media (min-width: 1500px) and (max-width: 1580px) {
  .table-view {
    .container {
      width: 1042px;
      margin-top: -24px;
    }
  }
}
@media (min-width: 1580px) and (max-width: 1650px) {
  .table-view {
    .container {
      width: 1166px;
      margin-top: -24px;
    }
  }
}

@media (min-width: 1650px) and (max-width: 1740px) {
  .table-view {
    .container {
      width: 1215px;
      margin-top: -24px;
    }
  }
}
@media (min-width: 1740px) and (max-width: 1773px) {
  .table-view {
    .container {
      width: 1300px;
      margin-top: -24px;
    }
  }
}

@media (min-width: 1773px) and (max-width: 1920px) {
  .table-view {
    .container {
      width: 1400px;
      margin-top: -24px;
    }
  }
}

@media (min-width: 1920px) and (max-width: 2400px) {
  .table-view {
    .container {
      width: 1400px;
      margin-top: -24px;
    }
  }
}
@media (min-width: 2400px) and (max-width: 4000px) {
  .table-view {
    .container {
      width: 2000px;
      margin-top: -24px;
    }
    .ant-spin-container {
      position: relative;
      transition: opacity 0.3s;
      width: 144%;
      right: 20pc;
    }
  }
}

button.ant-switch {
  border: 2px solid $light-theme-border-color;
  height: 21px;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0.2px;
  right: 0;
  bottom: 2px;
  left: 0;
  padding-top: 17px;
}

.ant-switch-handle {
  bottom: 2px;
}

.ant-pagination-options-size-changer.ant-select {
  font-size: 12px;
}

.ant-btn.ant-btn-default {
  color: $baseWhite;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}

button.ant-btn.ant-btn-primary {
  border-radius: 16px;
}

button.ant-btn.ant-btn-default {
  border-radius: 16px;
}

/* For larger height screens */
.custom-table .ant-table-body {
  overflow-y: scroll;
  max-height: 260px;
}

@media screen and (min-height: 600px) {
  .custom-table .ant-table-body {
    overflow-y: scroll;
    max-height: 270px;
  }
}

@media screen and (min-height: 800px) {
  .custom-table .ant-table-body {
    overflow-y: scroll;
    max-height: 295px;
  }
}

@media screen and (min-height: 900px) {
  .custom-table .ant-table-body {
    overflow-y: scroll;
    max-height: 400px;
  }
}

//Code addition - Vijay
.ant-table-pagination.ant-pagination {
  margin: 27px 0;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: $baseRed;
  border-radius: 12px;
  span.ant-tree-title {
    color: $baseWhite;
  }
}

.ant-tree .ant-tree-node-content-wrapper {
  white-space: nowrap;
}

// projectStutasTab icons

.projectStutasTab {
  .projectStutasTab-icons {
    position: absolute;
    right: 0;
    top: -45px;
    display: flex;
    gap: 50px;
    left: 597px;
    span.anticon.anticon-form {
      position: absolute;
      top: 3px;
      left: -15px;
    }
    .form-icon {
      font-size: 20px;
      color: $baseRed;
    }
    .pdf-icon {
      font-size: 20px;
      color: $baseRed;
    }
    .send-icon {
      font-size: 20px;
      color: $baseRed;
    }
  }
}

// projectSummary icons

.projectSummaryTab {
  background: $table-bg-white;
  border-radius: 16px;
  padding: 60px 10px 10px 10px;
  margin-bottom: 17px;
  position: relative;
  right: 35px;
  bottom: 42px;
  .projectSummary-icons {
    position: absolute;
    right: 0;
    top: 10px;
    display: flex;
    gap: 50px;
    left: 572px;
    span.anticon.anticon-form {
      position: absolute;
      top: 3px;
      left: -15px;
    }
    .form-icon {
      font-size: 20px;
      color: $baseRed;
    }
    .hide-icon {
      display: none;
    }
    .pdf-icon {
      font-size: 20px;
      color: $baseRed;
    }
    .send-icon {
      font-size: 20px;
      color: $baseRed;
    }
  }
}

// reportsTab icon

.reports {
  .report-tabs {
    .ant-tabs-nav {
      background: $table-bg-white 0% 0% no-repeat padding-box;
      width: 198px;
      border-radius: 14px;
      padding-left: 17px;
      padding-top: 17px;
      margin-top: -42px;
      .ant-tabs-nav-list {
        display: flex;
        flex-direction: column;
        .ant-tabs-tab {
          margin: 0px 0px 30px 0px;
          border: 1px solid $baseRed;
          justify-content: center;
          color: $baseWhite;
          padding: 7px;
          width: 165px;
        }
      }
    }
  }
  .ant-tabs.ant-tabs-top.report-tabs {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
  .report-icons {
    position: absolute;
    right: 0;
    top: 0px;
    display: flex;
    gap: 50px;
    left: 600px;

    span.anticon.anticon-form {
      position: absolute;
      top: 3px;
      left: -15px;
    }
    .form-icon {
      font-size: 20px;
      color: $baseRed;
    }
    .hide-icon {
      display: none;
    }
    .pdf-icon {
      font-size: 20px;
      color: $baseRed;
    }
    .send-icon {
      font-size: 20px;
      color: $baseRed;
    }
  }
}

@media screen and (min-width: 780px) {
  .projectSummaryTab .projectSummary-icons {
    left: 64px;
  }

  .reports .report-icons {
    left: 193px;
    top: 0px;
  }
}
@media screen and (min-width: 1000px) {
  .projectSummaryTab .projectSummary-icons {
    left: 363px;
  }

  .reports .report-icons {
    left: 390px;
    top: 0px;
  }
}
@media screen and (min-width: 1200px) {
  .projectSummaryTab .projectSummary-icons {
    left: 620px;
  }

  .reports .report-icons {
    left: 593px;
    top: 0px;
  }
}
@media screen and (min-width: 1370px) {
  .projectSummaryTab .projectSummary-icons {
    left: 720px;
  }

  .reports .report-icons {
    left: 695px;
    top: 0px;
  }
}

@media screen and (min-width: 1420px) {
  .projectSummaryTab .projectSummary-icons {
    left: 742px;
  }

  .reports .report-icons {
    left: 748px;
    top: 0px;
  }
}
@media screen and (min-width: 1450px) {
  .projectSummaryTab .projectSummary-icons {
    left: 790px;
  }

  .reports .report-icons {
    left: 690px;
    top: 0px;
  }
}
@media screen and (min-width: 1520px) {
  .projectSummaryTab .projectSummary-icons {
    left: 910px;
  }

  .reports .report-icons {
    left: 884px;
    top: 0px;
  }
}
@media screen and (min-width: 1700px) {
  .projectSummaryTab .projectSummary-icons {
    left: 993px;
  }

  .reports .report-icons {
    left: 1021px;
    top: 0px;
  }
}
@media screen and (min-width: 1800px) {
  .projectSummaryTab .projectSummary-icons {
    left: 1096px;
  }

  .reports .report-icons {
    left: 1124px;
    top: 0px;
  }
}
@media screen and (min-width: 1920px) {
  .projectSummaryTab .projectSummary-icons {
    left: 1214px;
  }

  .reports .report-icons {
    left: 1242px;
    top: 0px;
  }
}
@media screen and (min-width: 2000px) {
  .projectSummaryTab .projectSummary-icons {
    left: 1293px;
  }

  .reports .report-icons {
    left: 1321px;
    top: 0px;
  }
}
@media screen and (min-width: 2150px) {
  .projectSummaryTab .projectSummary-icons {
    left: 1460px;
  }

  .reports .report-icons {
    left: 1465px;
    top: 0px;
  }
}

.pipeData {
  background: $table-bg-white;
  padding: 60px 10px 48px 10px;
  border-radius: 16px;
  position: relative;
  right: 36px;
  margin-bottom: 15px;
  bottom: 42px;
  .table-container .switch-container {
    position: absolute;
    top: 10px;
    .ant-switch-handle {
      position: absolute;
      top: 4px;
      left: 3px;
      width: 18px;
      height: 18px;
      transition: all 0.2s ease-in-out;
    }
    button.ant-switch {
      border: 2px solid rgba(112, 112, 112, 0.22);
      height: 25px;
    }
    .ant-switch-checked .ant-switch-handle {
      left: calc(100% - 18px - 2px);
    }
  }
  .pipedata-table {
    .ant-table {
      .ant-table-thead {
        background: rgba(255, 255, 255, 0.1);
      }
      th.ant-table-cell {
        padding: 17px;
        border: 1px solid $light-theme-border-color;
        color: $baseRed;
      }
      td.ant-table-cell {
        border: 1px solid $light-theme-border-color;
        padding: 18px;
      }
      tr:nth-child(even) td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
      tr:nth-child(even) td:last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .ant-table-tbody tr.ant-table-row:nth-child(even) {
        background: none;
      }
      td:first-child {
        color: $baseRed;
      }
      .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: 15px;
      }
      .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 15px;
      }
    }
    .pipedata-nodata {
      margin-top: 7.5pc;
      margin-bottom: 7.3pc;
      padding: 10px 10px 135px 10px;
    }
  }
}

.pipeData-chart {
  @extend .pipeData;
  padding: 60px 10px 0px 10px;
}

.pipedata-graph {
  margin-top: -18px;
  .apexcharts-menu.apexcharts-menu-open {
    background: $baseBlack;
  }
  .apexcharts-menu.apexcharts-menu-open :hover {
    background: $baseWhite;
    color: $baseBlack;
  }
  .apexcharts-menu-item.exportSVG {
    display: none;
  }

  .apexcharts-menu-item.exportPNG {
    display: none;
  }

  .menuOutlined {
    display: flex;
    justify-content: end;
    margin-right: 18px;
    position: relative;
    bottom: 13px;

    .ant-dropdown.ant-dropdown-placement-bottomRight {
      width: 120px;
    }
  }

  @media (min-width: 2000px) and (max-width: 6000px) {
    .menuOutlined {
      bottom: 22px;
    }
  }
}

.project-status {
  background: $table-bg-white;
  border-radius: 16px;
  position: relative;
  bottom: 42px;
  padding: 20px 0px 6px 0px;
  right: 36px;

  .apexcharts-menu-icon {
    margin-bottom: 25px;
    margin-right: 25px;
  }
  .apexcharts-toolbar {
    bottom: 25pc;
  }
  .apexcharts-menu.apexcharts-menu-open {
    background: $baseBlack;
  }
  .apexcharts-menu.apexcharts-menu-open :hover {
    background: $baseWhite;
    color: $baseBlack;
  }
  .apexcharts-menu-item.exportSVG {
    display: none;
  }

  .apexcharts-menu-item.exportPNG {
    display: none;
  }
}

.peojectSummary {
  .apexcharts-menu.apexcharts-menu-open {
    background: $baseBlack;
  }
  .apexcharts-menu.apexcharts-menu-open :hover {
    background: $baseWhite;
    color: $baseBlack;
  }
  foreignObject {
    height: 349px;
  }
  .apexcharts-menu-item.exportSVG {
    display: none;
  }

  .apexcharts-menu-item.exportPNG {
    display: none;
  }
}

//Sequence tab
.sequenceTab-tree {
  padding: 5px;
  padding-top: 20px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
  border-color: $Grey;
  margin-bottom: 16px;
  .ant-tree {
    width: 188px;
    height: 344px;
  }
  .ant-tree-list-holder-inner {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 340px;
    width: 190px;
    scrollbar-width: none;
  }
  .parent-tree {
    color: $baseWhite;
  }
}

.sequenceTab-tree-table {
  padding: 5px;
  padding-top: 20px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
  border-color: $Grey;
  height: 96%;
  .ant-tree {
    width: 188px;
    height: 400px;
  }
  .ant-tree-list-holder-inner {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 340px;
    width: 190px;
    scrollbar-width: none;
  }
  .parent-tree {
    color: $baseWhite;
  }
}

.project-detail-bar {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  .project-detail-edit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2%;
    cursor: pointer;
  }
  .project-detail-bar-items {
    display: flex;
    flex: 95% 1 1;
  }
}

//project Status Graph
div#rc-tabs-3-panel-5 {
  right: 25px;
}

.apexcharts-toolbar .apexcharts-zoomin-icon svg {
  fill: $baseWhite;
}

.apexcharts-toolbar .apexcharts-zoomout-icon svg {
  fill: $baseWhite;
}

.apexcharts-toolbar .apexcharts-reset-icon svg {
  fill: $baseWhite;
}

.apexcharts-toolbar .apexcharts-menu-icon svg {
  fill: $baseWhite;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
  margin-bottom: -3px;
}

// Configuration Tab
.right-div .ant-form .ant-row > div[class*="ant-col-"] {
  flex-grow: 1;
}

//Report Tab Icon styles
.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip::before {
  content: attr(data-tooltip);
  position: absolute;
  top: 20px;
  left: 65%;
  transform: translateX(-50%);
  background-color: transparent;
  color: $baseRed;
  padding: 4px 8px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
  font-size: 12px;
}

.custom-tooltip:hover::before {
  visibility: visible;
  opacity: 1;
}
.custom-tooltip-min-max {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip-min-max::before {
  content: attr(data-tooltip);
  position: absolute;
  top: 24px;
  left: 30%;
  transform: translateX(-50%);
  background-color: transparent;
  color: $baseRed;
  padding: 4px 8px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
  font-size: 12px;
}

.custom-tooltip-min-max:hover::before {
  visibility: visible;
  opacity: 1;
}

span.custom-tooltip-min-max {
  position: relative;
  top: 3px;
}

@media screen and (max-width: 879px) {
  .ant-layout-sider-collapsed {
    /* Define collapsed width, transition, or other styles */
    width: 80px;
    transition: width 0.3s ease;
  }
}

.tree-div {
  width: 158px;
  height: 335px;
  margin-right: 25px;
  position: relative;
  bottom: 70px;
  border-radius: 14px;
  padding: 8px;
  margin-top: 12px;
}

//SideBar UserName Style
.username {
  position: relative;
  right: 15px;
  font-family: sans-serif;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: $input-bg;
  border-color: $light-theme-border-color;
}

.left-menu .ant-menu-item {
  color: $baseWhite;
}
.spread-popup {
  .select-drop {
    border-radius: 18px;
    border: 1px solid $baseWhite-transparant;
    color: $baseWhite;
    height: 33px;
    background-color: $input-bg;
  }
  .ant-form-vertical .ant-form-item-row {
    flex-direction: row;
  }
  .spread-save-btn {
    position: absolute;
    right: 2rem;
  }

  button.ant-btn.ant-btn-default {
    border-radius: 30px;
    height: 40px;
    border-color: white;
    width: 80px;
    right: 20px;
  }

  button.ant-btn.ant-btn-primary {
    border-radius: 30px;
    height: 40px;
    width: 80px;
    color: $baseWhite;
    background: $baseRed;
  }
}
.subspread-popup {
  .select-drop {
    border-radius: 18px;
    border: 1px solid $baseWhite-transparant;
    color: $baseWhite;
    height: 33px;
    background-color: $input-bg;
  }
  .ant-form-vertical .ant-form-item-row {
    flex-direction: row;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
    background: #ffffff1a;
  }
  .spread-save-btn {
    position: absolute;
    right: 2rem;
  }

  button.ant-btn.ant-btn-default {
    border-radius: 30px;
    height: 40px;
    border-color: white;
    width: 80px;
    right: 20px;
  }

  button.ant-btn.ant-btn-primary {
    border-radius: 30px;
    height: 40px;
    width: 80px;
    color: $baseWhite;
    background: $baseRed;
  }
}

.quality-modal {
  .select-drop {
    border-radius: 18px;
    border: 1px solid $baseWhite-transparant;
    color: $baseWhite;
    height: 33px;
    background-color: $input-bg;
  }
}

.delete-icon {
  width: 100%;
  color: $baseRed;
  font-size: 18px;
}

//sequence Tab Icon styles

.sequence-icon {
  position: relative;
  bottom: 50px;
  display: inline-block;
  left: 438px;

  span.anticon.anticon-file-pdf.sequence-pdf-icon {
    position: absolute;
    top: 0px;
    font-size: 20px;
    color: $baseRed;
    left: 28%;
  }

  span.anticon.anticon-eye.sequence-view-icon-rolebase {
    position: absolute;
    top: 0px;
    font-size: 23px;
    color: $baseRed;
    left: 28%;
  }

  span.anticon.anticon-eye.sequence-view-icon-unapproved {
    position: absolute;
    top: 0px;
    font-size: 23px;
    color: $baseRed;
    left: 28%;
  }

  span.anticon.anticon-delete.sequence-delete-icon {
    position: absolute;
    top: -1px;
    font-size: 20px;
    color: $baseRed;
    right: 36%;
    bottom: 3px;
  }

  span.anticon.anticon-eye.sequence-view-icon {
    position: absolute;
    bottom: 1px;
    font-size: 22px;
    color: $baseRed;
    right: 49%;
  }

  span.anticon.anticon-form.sequence-approved-icon {
    position: absolute;
    top: 0px;
    left: 38%;
    font-size: 20px;
    color: $baseRed;
  }

  span.anticon.anticon-file-pdf.sequence-pdf-icon-rolebase {
    position: absolute;
    top: 0px;
    left: 38%;
    font-size: 20px;
    color: $baseRed;
  }

  span.sequence-tooltip-send {
    position: absolute;
    width: 55px;
    right: 115px;
  }

  span.anticon.anticon-send.sequence-send-icon {
    position: absolute;
    top: 0px;
    font-size: 20px;
    color: $baseRed;
    left: 35%;
  }

  span.anticon.anticon-form.approved-icon-grey {
    color: $Grey;
  }

  span.sequence-tooltip-delete {
    position: absolute;
    right: 236px;
  }

  span.sequence-tooltip-send-email {
    position: absolute;
    right: 74%;
  }

  span.sequence-tooltip-view {
    position: absolute;
    right: 75%;
  }

  span.sequence-tooltip-approval {
    position: relative;
    left: 29%;
    bottom: 2px;
  }

  span.sequence-tooltip-rolebase {
    position: relative;
    width: 20px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    left: 16%;
    width: 30%;
  }

  .sequence-tooltip {
    cursor: pointer;
    display: inline-block;
    position: relative;
    left: 36%;
    width: 35%;
  }

  .sequence-tooltip-view-rolebase {
    cursor: pointer;
    display: inline-block;
    position: relative;
    left: 15%;
    width: 25%;
  }
  .sequence-tooltip-view-unapproved {
    cursor: pointer;
    display: inline-block;
    position: relative;
    left: 55%;
    width: 35%;
  }

  .sequence-share-tooltip {
    cursor: pointer;
    left: 91%;
    width: 35%;
  }

  .sequence-tooltip-send {
    cursor: pointer;
  }
  .sequence-tooltip::before {
    content: attr(data-tooltip);
    top: 20px;
    right: 60%;
    position: relative;
    transform: translateX(-50%);
    background-color: transparent;
    color: $baseRed;
    padding: 4px 8px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 12px;
  }
  .sequence-tooltip-view-rolebase::before {
    content: attr(data-tooltip);
    top: 20px;
    right: 12%;
    position: relative;
    transform: translateX(-50%);
    background-color: transparent;
    color: $baseRed;
    padding: 4px 8px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 12px;
  }
  .sequence-tooltip-view-unapproved::before {
    content: attr(data-tooltip);
    top: 20px;
    right: 70%;
    position: relative;
    transform: translateX(-50%);
    background-color: transparent;
    color: $baseRed;
    padding: 4px 8px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 12px;
  }
  .sequence-tooltip-approval::before {
    content: attr(data-tooltip);
    top: 20px;
    right: 0%;
    position: relative;
    transform: translateX(-50%);
    background-color: transparent;
    color: $baseRed;
    padding: 4px 8px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 12px;
  }
  .sequence-tooltip-rolebase::before {
    content: attr(data-tooltip);
    top: 20px;
    right: 52%;
    position: relative;
    transform: translateX(-50%);
    background-color: transparent;
    color: $baseRed;
    padding: 4px 8px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 12px;
  }
  .sequence-tooltip-delete::before {
    content: attr(data-tooltip);
    top: 20px;
    left: 3%;
    position: relative;
    transform: translateX(-50%);
    background-color: transparent;
    color: $baseRed;
    padding: 4px 0px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 12px;
  }
  .sequence-tooltip-view::before {
    content: attr(data-tooltip);
    top: 20px;
    right: 9px;
    position: relative;
    transform: translateX(-50%);
    background-color: transparent;
    color: $baseRed;
    padding: 4px 0px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 12px;
  }
  .sequence-tooltip-send::before {
    content: attr(data-tooltip);
    top: 20px;
    position: relative;
    transform: translateX(-50%);
    background-color: transparent;
    color: $baseRed;
    padding: 4px 8px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 12px;
    right: 70%;
  }

  .sequence-tooltip:hover::before {
    visibility: visible;
    opacity: 1;
  }
  .sequence-tooltip-view-rolebase:hover::before {
    visibility: visible;
    opacity: 1;
  }
  .sequence-tooltip-view-unapproved:hover::before {
    visibility: visible;
    opacity: 1;
  }
  .sequence-tooltip-approval:hover::before {
    visibility: visible;
    opacity: 1;
  }
  .sequence-tooltip-rolebase:hover::before {
    visibility: visible;
    opacity: 1;
  }
  .sequence-tooltip-delete:hover::before {
    visibility: visible;
    opacity: 1;
  }
  .sequence-tooltip-view:hover::before {
    visibility: visible;
    opacity: 1;
  }
  .sequence-tooltip-send:hover::before {
    visibility: visible;
    opacity: 1;
  }
}

.custom-dropdown .ant-select-item-option:hover {
  background-color: $Grey;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 15px;
}

.slides-divs.selected {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 2400px) {
  .sequence-icon {
    left: 721px;
  }
}

@media screen and (max-width: 2200px) {
  .sequence-icon {
    left: 651px;
  }
}

@media screen and (max-width: 2000px) {
  .sequence-icon {
    left: 564px;
  }
}

@media screen and (max-width: 1800px) {
  .sequence-icon {
    left: 457px;
  }
}

@media screen and (max-width: 1600px) {
  .sequence-icon {
    left: 353px;
  }
}

@media screen and (max-width: 1536px) {
  .sequence-icon {
    left: 397px;
  }
}

@media screen and (max-width: 1450px) {
  .sequence-icon {
    left: 350px;
  }
}

@media screen and (max-width: 1400px) {
  .sequence-icon {
    left: 278px;
  }
}

@media screen and (max-width: 1260px) {
  .sequence-icon {
    left: 148px;
  }
}

@media screen and (max-width: 1000px) {
  .sequence-icon {
    left: 138px;
  }
}
@media screen and (max-width: 800px) {
  .sequence-icon {
    left: 38px;
  }
}

//Sequence Approve icon style

.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.custom-approve-modal.ant-modal-confirm
  .ant-modal-confirm-btns {
  text-align: center;
}

@media (min-width: 1850px) and (max-width: 1900px) {
  .first-parent .left-div .three-buttons .delete-div {
    position: relative;
  }
}

@media (min-width: 1550px) and (max-width: 1650px) {
  .first-parent .left-div .three-buttons .delete-div {
    position: relative;
  }
}
@media (min-width: 1650px) and (max-width: 1850px) {
  .first-parent .left-div .three-buttons .delete-div {
    position: relative;
  }
}
@media (min-width: 1850px) and (max-width: 2000px) {
  .first-parent .left-div .three-buttons .delete-div {
    position: relative;
  }
}
@media (min-width: 2000px) and (max-width: 2200px) {
  .first-parent .left-div .three-buttons .delete-div {
    position: relative;
  }
}
@media (min-width: 2200px) and (max-width: 2400px) {
  .first-parent .left-div .three-buttons .delete-div {
    position: relative;
  }
}

.initial-loader {
  flex: 75%;
  text-align: center;
  position: relative;
  top: 100px;
}

.initial-sequence-load {
  position: relative;
  top: 100px;
}

//Sequence Table View modal Style

.Sequence-view {
  .table-container-sequence {
    .ant-table-wrapper {
      height: 550px;
    }

    .ant-table-wrapper .ant-table-container {
      height: 523px;
    }

    .custom-table .ant-table-body {
      max-height: 460px;
    }

    spin-container .ant-table-tbody > tr > td,
    .ant-table-wrapper .ant-table .ant-table-tbody > tr > td {
      vertical-align: middle;
      padding: 4px;
    }
  }
  .anticon {
    vertical-align: 2px;
  }
  a {
    position: relative;
    top: -1px;
  }
}

.icon-plus-del {
  font-size: 20px;
  color: $baseRed;
  cursor: pointer;
}
::selection {
  background-color: $baseRed;
  color: #fff;
}
//Pipe data Details
.sequence-head {
  display: flex;
  flex-direction: row;
  justify-content: end;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
  }
  .ant-select.filter-options.ant-select-single.ant-select-show-arrow {
    position: absolute;
    left: 15px;
  }

  .ant-select.filter-values.ant-select-single.ant-select-show-arrow {
    left: 16px;
  }

  span.anticon.anticon-filter {
    position: relative;
    font-size: 15px;
    top: 8px;
    left: 20px;
    color: $baseRed;
  }
  button.ant-btn.ant-btn-primary.assign {
    right: 6px;
    background: $baseRed;
    color: $baseWhite;
  }
  button.ant-btn.ant-btn-primary.disable {
    right: 6px;
  }

  button.ant-btn.ant-btn-primary.delete-all {
    background: $baseRed;
    margin-bottom: 16px;
  }
}

.pipe-status-table {
  span.anticon.anticon-left {
    vertical-align: 2px;
  }

  span.anticon.anticon-right {
    vertical-align: 2px;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 15px;
    top: 7px;
    background: $light-theme-border-color;
    height: 29px;
    border: none;
  }

  .ant-select-clear {
    position: absolute;
    top: 70%;
    line-height: 0.5;
    border-radius: 16px;
  }
  .search-filter {
    .ant-select-arrow {
      line-height: 0;
      position: absolute;
      top: 75%;
      line-height: 1;
    }
    .ant-select-selection-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    .ant-select-selection-overflow {
      overflow-x: hidden;
      overflow-y: hidden;
      height: 28px;
    }
    .ant-select-selection-item {
      width: 100%;
      border-radius: 16px;
    }
  }
  /* Ensures the page size dropdown shows upwards */
  .ant-select-dropdown {
    bottom: 100% !important;
    top: auto !important;
    margin-bottom: 8px;
  }
}

.pipeData-view-table {
  .ant-table-body {
    max-height: 455px;
  }
  .search-filter {
    .ant-select-arrow {
      line-height: 0;
      position: absolute;
      top: 75%;
      line-height: 1;
    }
    .ant-select-selection-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 15px;
    top: 7px;
    background: $light-theme-border-color;
    height: 29px;
    border: none;
  }

  .ant-select-clear {
    position: absolute;
    top: 70%;
    line-height: 0.5;
    border-radius: 16px;
  }
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: none;
}

.ant-radio-inner::after {
  background-color: $baseRed;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $baseRed;
}

.ant-radio:hover,
.ant-radio-inner {
  border-color: $baseRed !important;
}

.ant-form input[type="radio"]:focus {
  outline: none !important;
}
.assign-spread-subspread {
  .ant-modal-body {
    display: flex;
    justify-content: space-around;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
  }
}

.ant-popover {
  text-align: center;
}
.flexslider-bt {
  .sc-eDvSVe.lguFiu.rec.rec-carousel {
    height: 76px !important;
  }
}

.ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected
  span.ant-tree-title {
  position: relative;
  right: 23px;
}

.ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected[title^="Spread"] {
  width: 118px;
}

.ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected[title^="SubSpread"] {
  width: 118px;
  span.ant-tree-title {
    position: relative;
    right: 9px;
  }
}

.sequence-approval-stage {
  color: $baseWhite;
}
.eye-modal-popup {
  .ant-table {
    .ant-table-thead > tr > th {
      color: red;
      border-top: none;
    }
  }
  .ant-modal-content:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(52, 43, 43, 0);
  }
  .search-filter .ant-select-selection-overflow {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 28px;
  }
  .ant-select-multiple .ant-select-selection-item {
    border-radius: 16px;
    width: 100%;
  }

  span.anticon.anticon-close {
    color: $baseWhite;
  }
  span.anticon.anticon-close.CloseOutlined {
    color: $baseRed;
  }
  button.ant-btn.ant-btn-primary {
    color: $baseWhite;
  }
}

.apexcharts-xaxis-texts-g text {
  font-weight: normal !important;
}

//Pipedata Toggle bar style
.table-container {
  .ant-switch-handle::before {
    position: absolute;
    top: -2.2px;
    bottom: 2px;
    left: -1px;
    padding-top: 17px;
  }
  .anticon {
    vertical-align: 2px;
    color: $baseWhite;
  }
  a {
    position: relative;
    top: -1px;
  }
}

.sequenceNodata-table {
  margin-top: 100px;
}

.editconfigure {
  display: flex;
}

.edit-form {
  position: absolute;
  background: $light-theme-border-color;
  width: 62%;
  padding-left: 35px;
  height: 23rem;
  border-radius: 12px;
  top: -44.5px;
  .editicon {
    position: relative;
    right: 5px;
    top: 5px;
    .custom-tooltip::before {
      left: 43%;
    }
  }

  span.anticon.anticon-edit {
    color: $baseRed;
    font-size: 22px;
  }

  button.ant-btn.ant-btn-default.ant-edit-btn {
    margin-bottom: 25px;
    position: relative;
    left: 278px;
    border: none;
  }
  .conf-edit {
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }
  button.ant-btn.ant-btn-default.ant-btn.ant-btn-default {
    font-weight: 700;
    background: transparent;
    color: #fff;
    border-color: #fff;
    border-radius: 30px;
  }
  button.ant-btn.ant-btn-default.ant-btn-primary {
    background: $baseRed !important;
    color: $baseWhite !important;
    width: 80px;
  }
}

@media (min-width: 2451px) and (max-width: 3000px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      left: 230px;
      top: 5px;
    }
  }
}
@media (min-width: 2051px) and (max-width: 2450px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      left: 120px;
      top: 5px;
    }
  }
}
@media (min-width: 1951px) and (max-width: 2050px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      left: 120px;
      top: 5px;
    }
  }
}
@media (min-width: 1851px) and (max-width: 1950px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      left: 100px;
      top: 5px;
    }
  }
}
@media (min-width: 1751px) and (max-width: 1850px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      left: 78px;
      top: 5px;
    }
  }
}
@media (min-width: 1651px) and (max-width: 1750px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      left: 40px;
      top: 5px;
    }
  }
}
@media (min-width: 1555px) and (max-width: 1650px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      top: 5px;
    }
  }
}
@media (min-width: 1401px) and (max-width: 1499px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      right: 76px;
      top: 5px;
    }
  }
}
@media (min-width: 1367px) and (max-width: 1400px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      right: 76px;
      top: 5px;
    }
  }
}
@media (min-width: 1281px) and (max-width: 1366px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      right: 76px;
      top: 5px;
    }
  }
}
@media (min-width: 1245px) and (max-width: 1280px) {
  .edit-form {
    height: 23rem;
    .editicon {
      position: relative;
      right: 78px;
      top: 5px;
    }
  }
}
@media (min-width: 1100px) and (max-width: 1244px) {
  .edit-form {
    height: 24rem;
    .editicon {
      position: relative;
      right: 102px;
      top: 5px;
    }
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .edit-form {
    height: 25rem;
    .editicon {
      position: relative;
      right: 160px;
      top: 5px;
    }
  }
}
@media (min-width: 900px) and (max-width: 999px) {
  .edit-form {
    height: 27rem;
    .editicon {
      position: relative;
      right: 215px;
      top: 5px;
    }
  }
}
@media (min-width: 200px) and (max-width: 899px) {
  .edit-form {
    height: 31rem;
    .editicon {
      position: relative;
      right: 260px;
      top: 5px;
    }
  }
}

.add-configuration {
  .ant-form {
    margin-top: 30px;
    margin-bottom: -30px;
  }
  .select-drop {
    border-radius: 18px;
    color: $baseWhite;
    height: 33px;
    background-color: $input-bg;
  }
  .spread-save-btn {
    justify-content: flex-end;
    display: flex;
  }
  .ant-radio-group.ant-radio-group-outline {
    display: flex;
    justify-content: space-around;
  }
  .ant-form-vertical .ant-form-item-row {
    flex-direction: row;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
    background: $input-bg;
    border: 1px solid $light-theme-border-color;
  }
  button.ant-btn.ant-btn-default {
    border-radius: 16px;
    margin-right: 35px;
    width: 80px;
    height: 35px;
  }
  button.ant-btn.ant-btn-primary {
    border-radius: 16px;
    width: 80px;
    height: 35px;
    color: $baseWhite;
    background: $baseRed;
  }
}
/* sequence table width adjust */

.custom-table {
  table-layout: auto;
  /* Ensures the page size dropdown shows upwards */
  .ant-select-dropdown {
    bottom: 100% !important;
    top: auto !important;
    margin-bottom: 8px;
  }
}
.custom-table thead th,
.custom-table tbody td {
  white-space: nowrap;
}

.sup {
  color: $baseWhite;
  font-size: 14px;
}
// Import Data Tab Style
.confirmation-section {
  margin-top: 5rem;
  .description {
    color: $baseRed;
  }
}

.initial-importData-load {
  position: relative;
  top: 100px;
  margin-bottom: 300px;
}

.spreadImportData {
  background: $table-bg-white;
  padding: 25px 20px 30px 0px;
  width: 55%;
  height: 22rem;
  border-radius: 12px;
  margin-bottom: 17px;
  position: relative;
  .spread-description-field {
    display: flex;
    margin-top: 25px;
    .spread-description {
      font-size: 14px;
      word-spacing: 1px;
      display: block;
      margin-bottom: 17px;
      margin-left: 35px;
      margin-right: 8px;
    }
  }
  .ant-radio-group.ant-radio-group-outline.yes-no-btn {
    width: 165px;
  }
  .import-spread-dropdown {
    margin-top: 25px;
  }
  .import-btn {
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
  }

  button.ant-btn.ant-btn-primary.import-btn-yes {
    margin-right: 20px;
    background: transparent;
    border: 1px solid $baseWhite;
    width: 80px;
    color: $baseWhite;
    height: 40px;
    border-radius: 30px;
    font-weight: 700;
  }

  button.ant-btn.ant-btn-primary {
    border-radius: 30px;
    min-width: 80px;
    margin-right: 10px;
    color: $baseWhite;
    height: 40px;
    font-weight: 700;
  }

  .first-line {
    display: flex;
    flex-direction: row;
  }

  .second-line {
    display: flex;
    flex-direction: row;
  }

  .ant-select.import-spread.ant-select-single.ant-select-show-arrow {
    margin-right: 1rem;
    margin-bottom: 3rem;
  }
  .ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
    display: none;
  }

  .ant-select.import-subspread.ant-select-single.ant-select-show-arrow.ant-select-disabled {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.08);
    height: 36px;
    span.ant-select-selection-item {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .ant-select.import-subspread.ant-select-single.ant-select-show-arrow {
    margin-right: 1rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: 1px solid #434343;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 16px;
    width: 245px;
    height: 36px;
  }

  label.select-spread {
    margin: 5px 100px 0 37px;
  }

  label.select-subSpread {
    margin: 5px 75px 0 37px;
  }

  .create-spread {
    background: $baseRed;
    height: 33px;
    width: 33px;
    border-radius: 21px;
  }
  .icon-plus-del {
    font-size: 20px;
    color: $baseWhite;
    position: relative;
    right: 10px;
    bottom: 1px;
  }

  .create-subSpread {
    background: $baseRed;
    height: 33px;
    width: 33px;
    border-radius: 18px;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    background-color: transparent;
    border-color: #a61d24 !important;
    width: 100%;
  }

  .ant-select-selector:disabled {
    background: red;
  }

  button.ant-btn.ant-btn-primary.create-subSpread {
    width: 216px;
  }

  button.ant-btn.ant-btn-secondary {
    color: grey;
    border-radius: 16px;
    width: 0px;
  }

  button.ant-btn.ant-btn-primary.create-spread {
    width: 175px;
  }

  span.create-spread-disabled.ant-tooltip-disabled-compatible-wrapper {
    .icon-plus-del {
      color: rgba(255, 255, 255, 0.3);
    }
  }
  span.create-subSpread-disabled.ant-tooltip-disabled-compatible-wrapper {
    .icon-plus-del {
      color: rgba(255, 255, 255, 0.3);
    }
  }
  span.ant-select-selection-item {
    margin-top: 3px;
  }
  span.ant-select-arrow {
    top: 20px;
  }

  .ant-select.import-spread.ant-select-single.ant-select-show-arrow.ant-select-disabled {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.08);
    height: 36px;
    position: relative;
    span.ant-select-selection-item {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
@media (min-width: 1255px) and (max-width: 1400px) {
  .spreadImportData {
    height: 26rem;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 225px;
    }
    label.select-spread {
      margin: 5px 70px 0 37px;
    }

    label.select-subSpread {
      margin: 5px 45px 0 37px;
    }
  }
}
@media (min-width: 1000px) and (max-width: 1255px) {
  .spreadImportData {
    height: 26rem;
    .spread-description-field {
      .spread-description {
        overflow-x: scroll;
        height: 97px;
      }
    }
    label.select-spread {
      margin: 5px 70px 0 37px;
    }

    label.select-subSpread {
      margin: 5px 45px 0 37px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 77px;
    }
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .spreadImportData {
    height: 26rem;
    label.select-spread {
      margin: 5px 45px 0 37px;
    }

    label.select-subSpread {
      margin: 5px 19px 0 37px;
    }
    .spread-description-field {
      .spread-description {
        overflow-x: scroll;
        height: 97px;
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 77px;
    }
  }
}
@media (min-width: 200px) and (max-width: 800px) {
  .spreadImportData {
    height: 26rem;
    label.select-spread {
      margin: 5px 45px 0 37px;
    }

    label.select-subSpread {
      margin: 5px 19px 0 37px;
    }
    .spread-description-field {
      .spread-description {
        overflow-x: scroll;
        height: 97px;
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 25px;
    }
  }
}
.spreadImportData-table {
  background: $table-bg-white;
  padding: 15px 27px 20px 27px;
  margin: 0px 60px 15px 0px;
  border-radius: 50px;

  button.ant-btn.ant-btn-primary {
    border-radius: 30px;
    min-width: 80px;
    margin-right: 20px;
    color: $baseWhite;
    height: 40px;
    font-weight: 700;
  }
  .selected-files-list {
    .selected-json-file {
      color: $baseRed;
      font-size: 20px;
    }
    .json-upload {
      display: flex;
      justify-content: end;
      margin-top: 20px;
    }

    button.ant-btn.ant-btn-danger.cancel-all {
      border-radius: 20px;
      background: #bbb5b500;
      border-radius: 30px;
      min-width: 80px;
      border: 1px solid;
      margin-right: 20px;
      color: #fff;
      height: 40px;
      font-weight: 700;
    }
  }
}
button.ant-btn.ant-btn-default.ant-edit-btn::after {
  animation: none !important;
}

.ant-edit-btn {
  margin-bottom: 60px;
}

// Pipe Details Tab Styles

.pipe-details {
  .pipe-tabs {
    .ant-tabs-nav {
      width: 198px;
      border-radius: 14px;
      padding-left: 17px;
      padding-top: 17px;
      .ant-tabs-nav-list {
        display: flex;
        flex-direction: column;
        .ant-tabs-tab {
          margin: 0px 0px 30px 0px;
          border: 1px solid $baseRed;
          justify-content: center;
          color: $baseWhite;
          padding: 7px;
          width: 165px;
        }
      }
    }
  }
  .ant-tabs.ant-tabs-top.pipe-tabs {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
}

.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  line-height: 18px;
}

span.anticon.anticon-eye.pipefilter-view-icon {
  position: relative;
  font-size: 22px;
  color: $baseRed;
  right: 35px;
  top: 3px;
  margin-bottom: 16px;
}

.pipefilter-tooltip-view::before {
  content: attr(data-tooltip);
  top: 20px;
  left: 6px;
  position: relative;
  transform: translateX(-50%);
  background-color: transparent;
  color: $baseRed;
  padding: 4px 0px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
  font-size: 12px;
}

.pipefilter-tooltip-view:hover::before {
  visibility: visible;
  opacity: 1;
}

.search-filter {
  display: grid;
}

.input-filter-style {
  text-align: center;
  height: 28px;
  margin-top: 7px;
}

//Eye Icon Model popup Alignment

.ant-modal-wrap {
  position: fixed;
  top: -70px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}

.pipe-status-data {
  background: $table-bg-white;
  padding: 10px 10px 0px 10px;
  border-radius: 16px;
  margin-bottom: 17px;
  .ant-table table {
    width: 99%;
  }
  .ant-table-wrapper .ant-spin-nested-loading .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-spin-container .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table .ant-table-thead > tr > th {
    border-top: 0px solid #707070 !important;
  }
  .ant-space.ant-space-vertical {
    gap: 0px !important;
  }
}
.sequenceInProgress {
  button.ant-btn.ant-btn-primary {
    color: $baseWhite;
  }
}

.downloading-text-base {
  position: absolute;
  background-color: transparent;
  color: #e03540;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;

  .typing-animation {
    display: inline-flex;
    padding: 6px 10px;
    border-radius: 50px;
  }

  .typing-animation .dot {
    height: 10px;
    width: 10px;
    opacity: 0.7;
    margin: 0 3px;
    border-radius: 50%;
    background: $baseWhite;
    animation: animateDots 1.8s var(--delay) ease-in-out infinite;
  }

  @keyframes animateDots {
    0%,
    44% {
      transform: translateY(0px);
    }
    28% {
      opacity: 0.4;
      transform: translateY(-9px);
    }
    44% {
      opacity: 0.2;
    }
  }
}

/* Variants */
.downloading-text {
  @extend .downloading-text-base;
  top: 20px;
  left: -33px;
}
.downloading-text-sequence-email {
  @extend .downloading-text-base;
  top: 20px;
  left: -15px;
}

.downloading-text-sequence {
  @extend .downloading-text-base;
  top: 20px;
  left: -10px;
}

.downloading-text-configuration {
  @extend .downloading-text-base;
  top: 25px;
  left: -25px;
}

.checkOutLined {
  font-size: 18px;
  color: #0a9b0a;
}

.CloseOutlined {
  font-size: 18px;
  color: $baseRed;
}

//side bar logo align center while collaps
.logo-container .logo-collapsed .aside-logo-small img {
  width: 100%;
  margin-left: 12px;
}

.apexcharts-ycrosshairs,
.apexcharts-ycrosshairs-hidden {
  opacity: 0.7 !important;
  // transform: translateX(-5px); /* Move slightly to the left */
  //   transition: 0.15s ease all;
}
